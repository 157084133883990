// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs-min": `@media screen and (min-width: 320px)`,
	"sm-min": `@media screen and (min-width: 375px)`,
	"ml-min": `@media screen and (min-width: 426px)`,
	"mll-min": `@media screen and (min-width: 500px)`,
	"mdsedge-min": `@media screen and (min-width: 767.5px)`,
	"md-min": `@media screen and (min-width: 834px)`,
	"lg-min": `@media screen and (min-width: 1028px)`,
	"xl-min": `@media screen and (min-width: 1440px)`,
	"xxl-min": `@media screen and (min-width: 1850px)`,
	"xs-max": `@media screen and (max-width: 320px)`,
	"sm-max": `@media screen and (max-width: 375px)`,
	"ml-max": `@media screen and (max-width: 426px)`,
	"mll-max": `@media screen and (max-width: 500px)`,
	"mdsedge-max": `@media screen and (max-width: 767.5px)`,
	"md-max": `@media screen and (max-width: 834px)`,
	"lg-max": `@media screen and (max-width: 1028px)`,
	"xl-max": `@media screen and (max-width: 1440px)`,
	"xxl-max": `@media screen and (max-width: 1850px)`,
	"ml-md": `@media screen and (min-width: 426px) and (max-width: 833px)`,
	"mll-xl": `@media screen and (min-width: 500px) and (max-width: 1439px)`,
	"mll-md": `@media screen and (min-width: 500px) and (max-width: 833px)`,
	"md-lg": `@media screen and (min-width: 834px) and (max-width: 1027px)`,
	"lg-xl": `@media screen and (min-width: 1028px) and (max-width: 1439px)`,
	"lg-xll": `@media screen and (min-width: 1028px) and (max-width: 1849px)`,
	"xl-xxl": `@media screen and (min-width: 1440px) and (max-width: 1849px)`,
	"chalkWhiteFont": `#fff`,
	"drawerDefaultColor": `#05112a`,
	"fontFamilyTT": `TT Commons`,
	"fontFamilyHind": `Hind`,
	"fontFamilyMix": `Hind,Helvetica,Verdana,sans-serif`,
	"fontSize18": `18px`,
	"fontSize22": `22px`,
	"fontSize24": `24px`,
	"fontSize32": `32px`,
	"fontSize34": `34px`,
	"fontWeight400": `400`,
	"fontWeight700": `700`,
	"lineHeight150": `150%`,
	"profileFontColor": `#05112a`,
	"profileHoverBgColor": `#e8eefa`,
	"profileActiveBgColor": `#6d0176`,
	"thumbnailBackgroundColor": `#c4c4c4`,
	"whiteFont": `#fefefe`,
	"whiteLightFont": `#f7f9fd`,
	"dropDownLinkFocusColor": `#1977d4`,
	"profileButtonHover": `hsla(0,0%,100%,.2)`,
	"activeAvatarColor": `#003558`,
	"fontSize20": `20px`,
	"lineHeight24": `24px`,
	"imgContainerBorderColor": `#dcdcdc`,
	"fontSize14": `14px`,
	"lineHeight17": `16.73px`,
	"fontWeight500": `500`,
	"fontSize17": `16.2px`,
	"fontSize29": `28.8px`,
	"lineHeight29": `lineHeight29`,
	"fontSize40": `40px`,
	"lineHeight40": `40px`,
	"qrWhiteLightFont": `#fefefe`,
	"darkBgBorder": `#393f4a`,
	"swatchDarkFont": `#161c29`,
	"darkThemeColor": `#020917`,
	"indigoDefaultColor": `#05112a`,
	"greyColor": `#dde3ee`,
	"lightThemeColor": `#655591`,
	"lightGreyBgColor": `#e8eefa`,
	"lineHeight20": `20px`,
	"fontSize16": `16px`,
	"lineHeight28": `28px`,
	"dialogInfoSubheaderColor": `#020917`,
	"modalBorder": `#60646d`,
	"swatchDark800": `#272d39`,
	"qrErrorMsg": `#9a4341`,
	"copyright": `#333`,
	"greyBoxShadow": `#989ba3`,
	"blackColor": `#000`
};
export default ___CSS_LOADER_EXPORT___;
