/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { getSnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import CommonUtils from '../../common/utils/CommonUtils';
import * as constants from '../../common/constants';
import PopularChannelsSliderContanier from '../../common/components/PopularChannelsSliderContanier';
import ChannelExamsContainer from '../authhome/ChannelExamsContainer';
import env from '../../common/env';

import Onboarding from './Onboarding';

/**
 * @author Moahmed yasar arabath M
 */
export default class OnboardingPlugin {
  constructor(context) {
    this.context = context;
    this.language = this.context.props.language;
    this.onBoardFlow = true;
    this.onBoardFlowName = null;
    this.onboardWelcomeData = {
      id: constants.ONBOARDING_SLIDES.WELCOME_TO_PEARSONPLUS,
      template: 'templateB',
      title: this.language.getMessage('onboard.welcome'),
      imageUrl: '',
      description: this.language.getMessage('onboard.welcomeCourse'),
      list: {
        title: this.language.getMessage('onboard.welcomeCourseList'),
        items: []
      },
      subTitle1: '',
      description2: '',
      action: [
        {
          type: 'buttonAsActive',
          text: this.language.getMessage('onboard.next'),
          isInternalLink: true,
          action: 'next'
        }
      ]
    };
    this.onboardStudyPlan = {
      id: constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN,
      title: this.language.getMessage('onboard.studyPlan'),
      imageUrl: `${env.PLUS_URL}/auth-home/media/images/etextReadBook.png`,
      description: this.language.getMessage('onboard.addCourseAnother'),
      links: [
        {
          id: constants.ONBOARDING_LINKS.PURCHASE_ETEXT_BOOK,
          type: 'buttonAsLink',
          text: this.language.getMessage('onboard.purchase'),
          launchURL: `${env.BOOKSHELF_CONFIG.purchase}`,
          target: '_self',
          disabled: false
        },
        {
          id: constants.ONBOARDING_LINKS.PURCHASE_ETEXT_WITHOUT_BOOK,
          type: 'buttonAsLink',
          text: this.language.getMessage('onboard.addCourseWithoutEtext'),
          isInternalLink: true,
          action: 'next',
          disabled: false,
          disabledToolTipText: this.language.getMessage('onboard.disableTooltipText')
        }
      ],
      action: [
        {
          type: 'button',
          text: this.language.getMessage('onboard.mayBeLater'),
          isInternalLink: true,
          action: 'close',
          className: constants.PENDO_IDENTIFICATION_CLASS.ONBOARDING_MAYBE_LATER
        }
      ]
    };
    this.onboardAddcourse = {
      id: constants.ONBOARDING_SLIDES.ADD_COURSE,
      title: this.language.getMessage('onboard.aboutCourse'),
      description: this.language.getMessage('onboard.studyMaterials'),
      action: [
        {
          type: 'button',
          text: this.language.getMessage('onboard.mayBeLater'),
          isInternalLink: true,
          action: 'close',
          className: constants.PENDO_IDENTIFICATION_CLASS.ONBOARDING_MAYBE_LATER
        }
      ]
    };
    this.onboardSlideData = [];
    this.slideViewStart = null;
    this.onSlideViewed = null;
    this.onboardingViewEvents = {};
    this.onSlideViewedResolve = () => {};
    this.onSlideViewedReject = () => {};
  }

  updateChannelData = (slide, onboardFlow, activeChannelCards) => {
    const renderSlide = slide;
    if (slide.id === constants.ONBOARDING_SLIDES.ADD_COURSE) { // Updating the exam prep with new channel id
      renderSlide.component = this.getPopularChannelSlider(onboardFlow, activeChannelCards);
    }

    return renderSlide;
  }

  handleOnBoardPopularChannelCardClick = (index, onboardFlow) => {
    this.onSlideViewEnd();
    const { herobanner } = this.context.props;
    const activeChannelCards = CommonUtils.getActiveChannelCards(herobanner.popularChannels?.channelCards);
    const selectedChannelCard = activeChannelCards[index];
    let updatedChannelCard = [];
    if (selectedChannelCard) {
      updatedChannelCard = [...activeChannelCards];
      updatedChannelCard.splice(index, 1);
    }
    this.context.coursePlugin.handlePopularChannelCardClick(null, selectedChannelCard.channelId);
    this.context.setState((state) => {
      let { onboardSlideData } = state;
      onboardSlideData = onboardSlideData.map((slide) => {
        const renderSlide = this.updateChannelData(
          slide,
          constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE,
          updatedChannelCard
        );

        if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) {
          renderSlide.links = this.getOnboardLinks(updatedChannelCard.length <= 0);
        }

        return renderSlide;
      });

      return { onboardSlideData };
    });
    switch (onboardFlow) {
      // eslint-disable-next-line no-lone-blocks
      case constants.ONBOARDING_SLIDES_FLOW.ETEXT_WITH_MAPPED: {
        if (selectedChannelCard) {
          if (selectedChannelCard.showExams) {
            this.context.setState((state) => {
              let { onboardSlideData } = state;
              onboardSlideData = onboardSlideData.map((slide) => {
                const renderSlide = slide;
                if (slide.id === constants.ONBOARDING_SLIDES.EXAM_PREP) { // Updating the exam prep with new channel id
                  renderSlide.component = this.getChannelExam(
                    onboardFlow,
                    selectedChannelCard.channelId,
                    2
                  );
                }

                if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
                  renderSlide.title = this.language.getMessage('onboard.studyPlan');
                  renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
                }

                return renderSlide;
              });

              return { onboardSlideData, onboardGotoSlide: 1 };
            });
          } else { // channel have no exam prep so moved to study plan slide created
            this.context.setState((state) => {
              let { onboardSlideData } = state;
              onboardSlideData = onboardSlideData.map((slide) => {
                const renderSlide = this.updateChannelData(
                  slide,
                  constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE,
                  updatedChannelCard
                );

                if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
                  renderSlide.title = this.language.getMessage('onboard.courseAdded');
                  renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
                }

                return renderSlide;
              });

              return { onboardSlideData, onboardGotoSlide: 2 };
            });
          }
        }
      }
        break;
      // eslint-disable-next-line no-lone-blocks
      case constants.ONBOARDING_SLIDES_FLOW.ETEXT_WITHOUT_MAPPED: {
        if (selectedChannelCard) {
          // eslint-disable-next-line no-empty
          if (selectedChannelCard.showExams) {
            this.context.setState((state) => {
              let { onboardSlideData } = state;
              onboardSlideData = onboardSlideData.map((slide) => {
                const renderSlide = slide;
                if (slide.id === constants.ONBOARDING_SLIDES.EXAM_PREP) { // Updating the exam prep with new channel id
                  renderSlide.component = this.getChannelExam(
                    onboardFlow,
                    selectedChannelCard.channelId,
                    1
                  );
                }
                if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
                  renderSlide.title = this.language.getMessage('onboard.studyPlan');
                  renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
                }

                return renderSlide;
              });

              return { onboardSlideData, onboardGotoSlide: 3 };
            });
          } else {
            this.context.setState((state) => {
              let { onboardSlideData } = state;
              onboardSlideData = onboardSlideData.map((slide) => {
                const renderSlide = this.updateChannelData(
                  slide,
                  constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE,
                  updatedChannelCard
                );

                if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
                  renderSlide.title = this.language.getMessage('onboard.courseAdded');
                  renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
                }

                return renderSlide;
              });

              return { onboardSlideData, onboardGotoSlide: 1 };
            });
          }
        }
      }
        break;
      // eslint-disable-next-line no-lone-blocks
      case constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE: {
        if (selectedChannelCard) {
          // eslint-disable-next-line no-empty
          if (selectedChannelCard.showExams) {
            this.context.setState((state) => {
              let { onboardSlideData } = state;
              onboardSlideData = onboardSlideData.map((slide) => {
                const renderSlide = slide;
                if (slide.id === constants.ONBOARDING_SLIDES.EXAM_PREP) { // Updating the exam prep with new channel id
                  renderSlide.component = this.getChannelExam(
                    onboardFlow,
                    selectedChannelCard.channelId,
                    0
                  );
                }

                if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
                  renderSlide.title = this.language.getMessage('onboard.studyPlan');
                  renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
                }

                return renderSlide;
              });

              return { onboardSlideData, onboardGotoSlide: 3 };
            });
          } else {
            this.context.setState((state) => {
              let { onboardSlideData } = state;
              onboardSlideData = onboardSlideData.map((slide) => {
                const renderSlide = this.updateChannelData(
                  slide,
                  constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE,
                  updatedChannelCard
                );
                if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
                  renderSlide.title = this.language.getMessage('onboard.courseAdded');
                  renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
                }

                return renderSlide;
              });

              return { onboardSlideData, onboardGotoSlide: 0 };
            });
          }
        }
      }
        break;
      default:
        break;
    }
  }

  getPopularChannelSlider = (onboardFlow, channelCards) => {
    const popularChannelSliderProps = {
      channelCards: [...channelCards],
      styleVaraint: constants.VARIANT_C,
      onPopularChannelCardClick: (index) => {
        this.handleOnBoardPopularChannelCardClick(index, this.onBoardFlowName);
      },
      gap: 8
    };

    const popularChannelSlider = { Component: PopularChannelsSliderContanier, componentProps: popularChannelSliderProps };

    return popularChannelSlider;
  }

  getChannelExam = (onboardFlow, channelId, onboardGotoSlide) => {
    const { user, language, herobanner } = this.context.props;

    const channelExamsContainerProps = {
      userDetail: getSnapshot(user),
      env: env.ENVIRONMENT,
      locale: language.currentLanguage,
      channelId,
      onBoarding: true,
      onMaybeLater: () => {
        this.handleExamPrepMaybeLater(onboardGotoSlide);
      },
      onComplete: () => {
        const slideIndex = this.getCurrentSlideIndex();
        this.dispatchCTASlideEvent(constants.ONBOARDING_FLOW_EVENTS.BUTTONS.COMPLETE, slideIndex + 1, constants.ONBOARDING_SLIDES.SYLLABUS_CONFIRMATION);
        this.context.setState({ onboardGotoSlide });
        herobanner.setChannelId(null);
        herobanner.setChannelId(channelId);
        this.onSlideViewEnd();
      },
      onScreenChange: () => {
        this.onSlideViewEnd();
        setTimeout(() => {
          this.handleSlideView({ id: constants.ONBOARDING_SLIDES.SYLLABUS_CONFIRMATION });
        }, 500);
      },
      locationInApp: this.onBoardFlow ? constants.EXAM_PREP_FLOW.ONBOARDING_FLOW : constants.EXAM_PREP_FLOW.ADD_COURSE_FLOW
    };

    return { Component: ChannelExamsContainer, componentProps: channelExamsContainerProps };
  }

  /**
   * Disables add course link and returns all onboarding links
   *
   * @param {Boolean} disableAddCourse
   * @returns
   */
  getOnboardLinks = disableAddCourse => [...this.onboardStudyPlan.links].map((link) => {
    if (link.id === constants.ONBOARDING_LINKS.PURCHASE_ETEXT_WITHOUT_BOOK) {
      return {
        ...link,
        disabled: disableAddCourse
      };
    }

    return { ...link };
  });

  /**
   * Set onboard slide
   *
   *
   */
  setOnboardSlideData = () => {
    const { herobanner } = this.context.props;
    const popularChannelsData = getSnapshot(herobanner).popularChannels;
    const activeChannelCards = CommonUtils.getActiveChannelCards(popularChannelsData.channelCards);


    if (CommonUtils.isHEIUser()) {
      this.onboardStudyPlan.links = this.onboardStudyPlan.links.filter(
        link => link.id !== constants.ONBOARDING_LINKS.PURCHASE_ETEXT_BOOK
      );
    }
    // If there is no channel card then neeed to remove purchase without etextbook
    this.onboardStudyPlan.links = this.getOnboardLinks(activeChannelCards.length <= 0);

    this.addCourseSlideData = [
      {
        ...this.onboardStudyPlan,
        title: this.language.getMessage('onboard.addCourseAnother'),
        description: '  '
      },
      { ...this.onboardAddcourse, component: this.getPopularChannelSlider(constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE, activeChannelCards) },
      {
        id: constants.ONBOARDING_SLIDES.EXAM_PREP,
        template: constants.TEMPLATE_C
      }
    ];

    // eText with mapped Channel onboarding slide data
    this.bookWithChannelMappedSlideData = [
      { ...this.onboardWelcomeData },
      {
        id: constants.ONBOARDING_SLIDES.EXAM_PREP,
        template: constants.TEMPLATE_C
      },
      { ...this.onboardStudyPlan },
      { ...this.onboardAddcourse, component: this.getPopularChannelSlider(constants.ONBOARDING_SLIDES_FLOW.ETEXT_WITH_MAPPED, activeChannelCards) }
    ];

    // eText without mapped Channel onboarding slide data
    this.bookWithOutChannelMappedSlideData = [
      { ...this.onboardWelcomeData },
      { ...this.onboardStudyPlan, description: this.language.getMessage('onboard.addCourseAnother'), title: this.language.getMessage('onboard.courseAdded') },
      { ...this.onboardAddcourse, component: this.getPopularChannelSlider(constants.ONBOARDING_SLIDES_FLOW.ETEXT_WITHOUT_MAPPED, activeChannelCards) },
      {
        id: constants.ONBOARDING_SLIDES.EXAM_PREP,
        template: constants.TEMPLATE_C
      }
    ];

    // MLM book onboarding slide data
    this.MLMBookOnboardingData = [
      {
        ...this.onboardWelcomeData,
        action: [
          {
            type: 'button',
            text: this.language.getMessage('onboard.close'),
            isInternalLink: true,
            action: 'close'
          }
        ]
      }
    ];
  }

  /**
   * Show course on board
   *
   */
  showCourseOnBoard = () => {
    const { herobanner } = this.context.props;
    const activeChannelCards = CommonUtils.getActiveChannelCards(herobanner.popularChannels?.channelCards);
    this.onBoardFlow = false;
    this.onBoardFlowName = constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE;

    const addCourseSlideData = [
      {
        ...this.onboardStudyPlan,
        title: this.language.getMessage('onboard.addCourseAnother'),
        description: '  ',
        links: this.getOnboardLinks(activeChannelCards.length <= 0)
      },
      { ...this.onboardAddcourse, component: this.getPopularChannelSlider(constants.ONBOARDING_SLIDES_FLOW.ADD_COURSE, activeChannelCards) },
      {
        id: constants.ONBOARDING_SLIDES.EXAM_PREP,
        template: constants.TEMPLATE_C
      }
    ];
    this.context.setState({
      openOnBoard: true,
      onboardSlideData: [...addCourseSlideData],
      showOnBoardClose: false,
      onboardGotoSlide: 0
    });
  };

  handleExamPrepMaybeLater = (onboardGotoSlide) => {
    const slideIndex = this.getCurrentSlideIndex();
    this.dispatchCTASlideEvent(constants.ONBOARDING_FLOW_EVENTS.BUTTONS.MAYBE_LATER, slideIndex + 1, constants.ONBOARDING_SLIDES.EXAM_PREP);
    this.context.setState((state) => {
      let { onboardSlideData } = state;
      onboardSlideData = onboardSlideData.map((slide) => {
        const renderSlide = slide;
        if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) { // Updating the exam prep with new channel id
          renderSlide.title = this.language.getMessage('onboard.courseAdded');
          renderSlide.description = this.language.getMessage('onboard.addCourseAnother');
        }

        return renderSlide;
      });

      return { onboardSlideData, onboardGotoSlide };
    });
  }

  /**
   * Construct the welcome onboard slide data
   *
   * @param {*} recentMappedBook
   */
  constructSlideData = (recentMappedBook) => {
    const { user, language, herobanner } = this.context.props;
    const { enableOnBoardClose } = this.context.state.flags;
    let showOnBoardClose = false;
    let showExams = false;
    const isBundleUser = CommonUtils.hasSubscription(user, [constants.ACTIVE], [constants.BUNDLE]);
    const hasChannelSubscription = CommonUtils.isChannelsSubscriber();
    const courseId = recentMappedBook?.course_id;
    const isMyLabSubscription = CommonUtils.isMyLabSubscription(
      courseId,
      recentMappedBook?.product_entitlements?.business_model_code
    );
    const channelExamsContainerProps = {
      userDetail: getSnapshot(user),
      env: env.ENVIRONMENT,
      locale: language.currentLanguage,
      onBoarding: true,
      onMaybeLater: () => {
        this.handleExamPrepMaybeLater(2);
      },
      onComplete: () => {
        const slideIndex = this.getCurrentSlideIndex();
        this.dispatchCTASlideEvent(constants.ONBOARDING_FLOW_EVENTS.BUTTONS.COMPLETE, slideIndex + 1, constants.ONBOARDING_SLIDES.SYLLABUS_CONFIRMATION);
        this.context.setState({ onboardGotoSlide: 2 });
        herobanner.setChannelId(null);
        herobanner.setChannelId(recentMappedBook.channel_id);
        this.onSlideViewEnd();
      },
      onScreenChange: () => {
        this.onSlideViewEnd();
        this.handleSlideView({ id: constants.ONBOARDING_SLIDES.SYLLABUS_CONFIRMATION });
      },
      locationInApp: this.onBoardFlow ? constants.EXAM_PREP_FLOW.ONBOARDING_FLOW : constants.EXAM_PREP_FLOW.ADD_COURSE_FLOW
    };
    if (recentMappedBook.channel_id && (
      CommonUtils.localStringCompare(recentMappedBook.channel_mapping_type, constants.CHANNEL_MAPPING_TYPES.EXACT)
      || this.context.coursePlugin.isExactLiteMapping(recentMappedBook.channel_mapping_type)
      )) {
      const channelData = herobanner.getChannelData(recentMappedBook.channel_id);
      showExams = channelData ? channelData.showExams : false;
      channelExamsContainerProps.channelId = recentMappedBook.channel_id;
    }
    if (isMyLabSubscription) {
      this.onBoardFlowName = constants.ONBOARDING_SLIDES_FLOW.MLM_ONBOARD;
      this.onboardSlideData = [...this.MLMBookOnboardingData];
    } else if (showExams) {
      this.onBoardFlowName = constants.ONBOARDING_SLIDES_FLOW.ETEXT_WITH_MAPPED;
      this.onboardSlideData = [...this.bookWithChannelMappedSlideData];
    } else {
      this.onBoardFlowName = constants.ONBOARDING_SLIDES_FLOW.ETEXT_WITHOUT_MAPPED;
      this.onboardSlideData = [...this.bookWithOutChannelMappedSlideData];
    }

    const onboardSlideData = this.onboardSlideData.map((slide) => {
      const renderSlide = slide;
      if (slide.id === constants.ONBOARDING_SLIDES.WELCOME_TO_PEARSONPLUS) {
        renderSlide.imageUrl = recentMappedBook.cover_image_url || constants.DEFAULT_BOOK_COVER_IMAGE_PATH;
        renderSlide.list.items.push(
          {
            id: constants.PPLUS_SUBSCRIPTIONS.ETEXTBOOK,
            iconName: 'success',
            title: this.context.props.language.getMessage('subscription.eTextbook')
          }
        );

        /**
         * If bundle user copy - Study & Exam Prep Pack
         *
         */
        if (isBundleUser) {
          renderSlide.list.items.push(
            {
              id: constants.PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP_PACK,
              iconName: 'success',
              title: this.context.props.language.getMessage('subscription.studyBundle')
            }
          );
          renderSlide.subTitle1 = this.context.props.language.getMessage('hero.insideStudyExamPrepPack');
          renderSlide.description2 = this.context.props.language.getMessage('hero.userSubsGuidence');
        }

        /**
         * For all other users - Study & Exam Prep
         *
         */
        if (!isBundleUser) {
          renderSlide.list.items.push(
            {
              id: constants.PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP,
              iconName: hasChannelSubscription ? 'success' : 'fail',
              title: this.context.props.language.getMessage('subscription.studyNoBundle')
            }
          );
          renderSlide.subTitle1 = this.context.props.language.getMessage('hero.insideStudyExamPrep');
          renderSlide.description2 = this.context.props.language.getMessage('hero.testVideoLessons');
        }

        renderSlide.description = renderSlide.description.replace('{COURSE_NAME}', recentMappedBook.courseName || recentMappedBook.book_title || '');
      }

      if (slide.id === constants.ONBOARDING_SLIDES.EXAM_PREP) {
        renderSlide.component = { Component: ChannelExamsContainer, componentProps: channelExamsContainerProps };
      }

      if (slide.id === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) {
        const index = CommonUtils.isHEIUser() ? 0 : 1;

        if (renderSlide.links[index]) {
          renderSlide.links[index].text = isBundleUser ? this.language.getMessage('onboard.addCourseWithEtextBundle') : this.language.getMessage('onboard.addCourseWithoutEtext');
        }

        if (renderSlide?.links?.length === 2 && isBundleUser) {
          renderSlide.links.reverse();
        }
      }
      return renderSlide;
    });

    const eventData = {
      eventType: 'PUT',
      payload: {
        userActivity: {
          ...this.context.userPreferences.userActivity,
          bookWithOnboard: true
        }
      }
    };
    if (this.context.userPreferences && this.context.userPreferences.userActivity) {
      this.context.userPreferences.userActivity.bookWithOnboard = true;
    }
    Framework.getEventManager().publish(constants.USER_PREFERENCES_REQUESTED, eventData);

    // If the user is no bundle and DTC with mapped channel
    if (enableOnBoardClose
      && recentMappedBook.channel_id
      && recentMappedBook.product_entitlements?.business_model_code === constants.DIRECT_TO_CONSUMER
      && !CommonUtils.isBundleSubscriber()) {
      showOnBoardClose = true;
    }
    if (recentMappedBook.channel_id
      && recentMappedBook.product_entitlements?.business_model_code === constants.DIRECT_TO_CONSUMER
      && !CommonUtils.isBundleSubscriber()) {
      const { ONBOARDING_HEI_AB_TESTING_EVENT } = constants;
      const eventLabel = enableOnBoardClose ? ONBOARDING_HEI_AB_TESTING_EVENT.AB_TESTING_GROUP_A_EVENT_LABEL : ONBOARDING_HEI_AB_TESTING_EVENT.AB_TESTING_GROUP_B_EVENT_LABEL;
      CommonUtils.logABTestingEvent(eventLabel, ONBOARDING_HEI_AB_TESTING_EVENT.ONBOARDING_FIELD);
    }
    this.context.setState({ openOnBoard: true, onboardSlideData, showOnBoardClose });
  }

  sortBySubStartDate = (books = []) => {
    if (books && books.length > 0) {
      books.sort((a, b) => new Date(b?.product_entitlements?.start_date)
        - new Date(a?.product_entitlements?.start_date));
    }

    return books;
  }

  /**
   * get recent active book with mapped channel
   *
   * @returns
   */
  getRecentBookMappedChannel = () => {
    let foundBook = null;
    const { bookshelf } = this.context.props;
    const books = getSnapshot(bookshelf).books;
    let subscriptionType = constants.AUTHHOME_UNBOXING_FLOW.OTHER;

    const activeBooks = this.sortBySubStartDate(CommonUtils.getActiveBooks(books));
    const { unArchivedBooks } = CommonUtils.getArchivedOrUnArchivedBooks(activeBooks);

    if (unArchivedBooks.length > 0) {
      const MLMbook = unArchivedBooks.find((bookData) => {
        const courseId = bookData?.course_id;
        const isMyLabSubscription = CommonUtils.isMyLabSubscription(
          courseId,
          bookData.product_entitlements?.business_model_code
        );

        return isMyLabSubscription;
      });

      const DTCBook = unArchivedBooks.find(bookData => CommonUtils.isPplusOrIAbookOrHEI(bookData.product_entitlements?.business_model_code));

      if (MLMbook) {
        foundBook = MLMbook;
        subscriptionType = constants.AUTHHOME_UNBOXING_FLOW.MLM;
      } else if (DTCBook) {
        foundBook = DTCBook;
        subscriptionType = constants.AUTHHOME_UNBOXING_FLOW.DTC;
      } else {
        foundBook = unArchivedBooks[0];
      }
    }

    return { recentBookwithMappedChannel: foundBook, subscriptionType };
  }

  /**
   * Handle popup onclose
   *
   */
  handleOnClose = () => {
    this.context.setState({ openOnBoard: false, onboardGotoSlide: 0 });
  };

  /**
   * Handles oboarding flow click events
   *
   * @param {*} eventLabel
   * @param {*} slideInfo
   */
  handleOnboardingClickEvents = (eventLabel, slideInfo = {}) => {
    const {
      AUTH_HOME_CATEGORY,
      ONBOARDING_FLOW_EVENTS: { EVENT_ACTION },
      EVENT_AUTHHOMEEVENT
    } = constants;
    const { user } = this.context.props;
    const isBundleUser = CommonUtils.hasSubscription(getSnapshot(user), [constants.ACTIVE], [constants.BUNDLE]);
    const {
      slideId,
      slideIndex
    } = slideInfo;

    const customFields = {
      location_in_app: slideId,
      channels_p_bundle_subs: isBundleUser,
      event_value: slideIndex
    };

    CommonUtils.dispatchGaEvent(
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      EVENT_ACTION,
      eventLabel,
      customFields
    );
  }

  /**
   * Hanles slide view end
   *
   */
  onSlideViewEnd = () => {
    if (this.onBoardFlow) {
      this.onSlideViewedResolve(new Date());
    }
  }

  /**
   * Returns number of seconds for instrumentation
   *
   * @param {*} slideViewStart
   * @param {*} slideViewEnd
   * @returns
   */
  getNoOfSeconds = (slideViewStart = this.slideViewStart, slideViewEnd) => {
    let noOfSeconds = '';

    if (slideViewStart && slideViewEnd) {
      noOfSeconds = Math.round((slideViewEnd?.getTime() - slideViewStart?.getTime()) / 1000);

      if (noOfSeconds > 10) noOfSeconds = '10+';
    }

    return `${noOfSeconds}`;
  };

  /**
   * Saves previous onboarding view events
   *
   * @param {*} eventLabel
   */
  saveOnboardingViewEvents = (eventLabel) => {
    const {
      AUTH_HOME_CATEGORY,
      LOAD_AUTHHOME_WEB_ONBOARDING,
      EVENT_AUTHHOMEEVENT
    } = constants;
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount(),
      channels_sub_status: CommonUtils.channelsSubStatus(),
      user_entitlement_mix: CommonUtils.userEntitlementMixLabel
    };

    this.onboardingViewEvents = {
      eventCategory: AUTH_HOME_CATEGORY,
      event: EVENT_AUTHHOMEEVENT,
      eventAction: LOAD_AUTHHOME_WEB_ONBOARDING,
      eventLabel,
      customFields
    };
  }

  /**
   * Handles onboarding slide back button click
   *
   * @param {*} slideInfo
   */
  handleBackBtnClick = (slideInfo) => {
    const { BUTTONS: { BACK } } = constants.ONBOARDING_FLOW_EVENTS;

    this.onSlideViewEnd();
    this.handleOnboardingClickEvents(BACK, slideInfo);
  }

  /**
   * Handles onboarding slide next button click
   *
   * @param {*} slideInfo
   */
  handleNextBtnClick = (slideInfo) => {
    const { BUTTONS: { NEXT } } = constants.ONBOARDING_FLOW_EVENTS;

    this.onSlideViewEnd();
    this.handleOnboardingClickEvents(NEXT, slideInfo);
  }

  /**
   * Handles slide buttons click
   *
   * @param {*} ctaId
   * @param {*} slideIndex
   * @param {*} isInternalLink
   * @param {*} launchURL
   * @param {*} action
   */
  handleButtonClick = (ctaId, slideIndex, isInternalLink, launchURL, action) => {
    const { GOTO, CLOSE, NEXT } = constants.ONBORAD_ACTION;
    if (isInternalLink && !!action.action) {
      switch (action.action.toUpperCase()) {
        case GOTO:
          this.context.setState({ onboardGotoSlide: action.value });
          break;
        case CLOSE:
          this.handleOnClose();
          this.dispatchCTASlideEvent(constants.ONBOARDING_FLOW_EVENTS.BUTTONS.MAYBE_LATER, slideIndex);
          break;
        // eslint-disable-next-line no-lone-blocks
        case NEXT: {
          this.context.setState({ onboardGotoSlide: ((slideIndex - 1) + 1) });
          if (ctaId === constants.ONBOARDING_SLIDES.WELCOME_TO_PEARSONPLUS) {
            this.dispatchCTASlideEvent(constants.ONBOARDING_FLOW_EVENTS.BUTTONS.NEXT, slideIndex);
          }
        }
          break;
        default:
          break;
      }
    }
    if (action && action?.type === 'buttonAsLink' && (ctaId === constants.ONBOARDING_SLIDES.CUSTOM_STUDY_PLAN) && this.onBoardFlow) {
      CommonUtils.dispatchGaEvent(
        constants.AUTHHOME_APPLICATION,
        constants.EVENT_USER_INTERACTION,
        constants.ADD_COURSE_EVENT.ACTION_NAME,
        null,
        {
          location_in_app: constants.LOCATION_IN_APP.ONBOARDING,
          container_value: CommonUtils.getCurrentScreenEventLabel(this.context.state?.flags)
        }
      );
    }
    this.onSlideViewEnd();
  }

  dispatchCTASlideEvent = (eventLabel, slideIndex, locationInApp = null) => {
    if (this.onBoardFlow) {
      const slideData = this.context.state.onboardSlideData[(slideIndex - 1)];
      const reRenderLocationInApp = locationInApp || slideData?.id || null;
      CommonUtils.dispatchGaEvent(
        constants.AUTH_HOME_CATEGORY,
        constants.EVENT_AUTHHOMEEVENT,
        constants.CLICK_AUTHHOME_WEB_ONBOARDING,
        eventLabel,
        {
          is_ghost_account: CommonUtils.isGhostAccount(),
          channels_sub_status: CommonUtils.channelsSubStatus(),
          user_entitlement_mix: CommonUtils.userEntitlementMixLabel,
          event_value: slideIndex,
          location_in_app: reRenderLocationInApp
        }
      );
    }
  }

  /**
   * Dispatches previous slide view event
   *
   */
  dispatchPreviousSlideViewEvent = () => {
    this.onSlideViewed.then((slideViewEnd) => {
      const {
        eventCategory,
        event,
        eventAction,
        eventLabel,
        customFields
      } = this.onboardingViewEvents;
      customFields.event_value = this.getNoOfSeconds(this.slideViewStart, slideViewEnd);

      CommonUtils.dispatchGaEvent(
        eventCategory,
        event,
        eventAction,
        eventLabel,
        customFields
      );
    });
  }

  /**
   * Handles on slide view
   *
   * @param {*} currentSlide
   */
  handleSlideView = (currentSlide = {}) => {
    const { id } = currentSlide;
    this.slideViewStart = new Date();
    this.onSlideViewed = new Promise((resolve, reject) => {
      this.onSlideViewedResolve = resolve;
      this.onSlideViewedReject = reject;
    });
    this.dispatchPreviousSlideViewEvent();
    this.saveOnboardingViewEvents(id);
  }

  /**
   * Handle subscription status link from onboard
   *
   * @param {*} id
   */
  onSubStatusCardLinksClick = (id) => {
    if (id === constants.PPLUS_SUBSCRIPTIONS.STUDY_EXAM_PREP) {
      window.open(`${env.STUDY_CHANNEL_URL}/subscription`);
    }
  };

  /**
   * To get current slide index
   *
   * @returns
   */
  getCurrentSlideIndex = () => {
    const currentSlide = document.querySelector(`#${constants.ONE_READER_MODAL_ONBOARD_POPUP} .slick-active`);
    const slideIndex = (currentSlide && currentSlide.getAttribute('data-index')) || 0;

    return parseInt(slideIndex, 0);
  }

  /**
  * X close handle on modal
  *
  */
  onCloseModalCTA = () => {
    const slideIndex = this.getCurrentSlideIndex();
    this.dispatchCTASlideEvent(constants.ONBOARDING_FLOW_EVENTS.BUTTONS.CLOSE_X, slideIndex + 1);
    this.handleOnClose();
  }

  /**
   * Render Welcome onbaord flow
   *
   * @returns
   */
  renderOnboard = () => (this.context.state.onboardSlideData.length > 0 ? (
    <Onboarding
      openOnBoard={this.context.state.openOnBoard}
      onboardSlideData={this.context.state.onboardSlideData}
      gotoSlide={this.context.state.onboardGotoSlide}
      showCloseButton={this.context.state.showOnBoardClose}
      callbacks={{
        onClose: this.onCloseModalCTA,
        onBackBtnClick: this.handleBackBtnClick,
        onNextBtnClick: this.handleNextBtnClick,
        onSlideView: this.handleSlideView,
        onButtonClick: this.handleButtonClick,
        onSubStatusCardLinksClick: this.onSubStatusCardLinksClick
      }}
    />
  ) : null)
}
