/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * @author Mohamed yasar arabath M
 */

import React from 'react';
import Framework from '@greenville/framework';
import { getSnapshot } from 'mobx-state-tree';
import { BerlinServices } from '@vega/aitutor';
import axios from 'axios';
import AuthHomeChatComponent from '../AuthHomeChatComponent';
import env from '../../../common/env';
import CommonUtils from '../../../common/utils/CommonUtils';
import TocMapper from '../../../common/utils/TocMapper';
import * as constants from '../../../common/constants';

export default class AuthhomeAIStudyToolPlugin {
  constructor(context = {}, location = constants.AUTH_HOME) {
    this.context = context;
    this.location = location;
    this.AIStudyToolInitialLoad = false;
  }

  renderAIChatBot = () => {
    const { user, herobanner, asset } = this.context.props;
    const {
      flags,
      executeCommand,
      openBotContainer
    } = this.context.state;
    const {
      enableParagraph,
      enableFreeResponse,
      showAITutor,
      isAIReviewUser,
      enableAITutorOnAuthHome,
      enableMathMLKeyboard,
      AIEnableMarkdown,
      enableProblemSolveFeature,
      topicTimeOut,
      enableStreamingAPI,
      delayMessageTimerInSecs,
      enableAIShowMore: enableShowMore,
      enableAISleepMode,
      showAISleepModeAB,
      enableSummaryContinuation: showSummaryQuiz,
      enableExplainQuizOptions,
      AIChatPlaceHolderText,
      enableTopicInQuizFlow,
      ChannelsVideoScore,
      showUserHistory,
      enableAIShortAnswer: listOfBookAIShortAnswer,
      hideChannelsPopout,
      showRecommendedPrompts,
      AIBerlinTurnOffSummary,
      AIBerlinTurnOffPractice
    } = flags || {};
    const {
      username,
      initials
    } = getSnapshot(user);
    const {
      bannerType,
      tableOfContents
    } = getSnapshot(herobanner);
    const heroBook = this.context.heroDashboardPlugin.getHeroBook() || {};
    const {
      book_title: bookTitle = '',
      index_id: indexId = '',
      last_location_id: lastLocationPageId = '',
      book_id: bookID = '',
      product_id: productId = '',
      last_location_read: lastLocationChapterTitle = '',
      last_accessed_print_page_number: chapterNumber = '',
      book_type: contentType = '',
      product_model: productModel
    } = heroBook;
    const isAITutorEnabled = CommonUtils.isAHAITutorEnabled(
      enableAITutorOnAuthHome, showAITutor, isAIReviewUser, heroBook?.isAITutorSupported
    );
    const isTableOfContentsFetched = !tableOfContents?.isTOCFetchFailed
      && (tableOfContents && !CommonUtils.isEmpty(tableOfContents));

    if (!isAITutorEnabled
      || (!isTableOfContentsFetched)) return null;

    const pagePlaylist = asset.getAIPagePlaylist(contentType);

    const { chapterList, tocData } = this.getAIChatChapterListAndToc({
      tableOfContents,
      heroBook
    });
    const chapterId = this.getCurrentChapterId(chapterList, lastLocationPageId);
    const showSummary = Array.isArray(AIBerlinTurnOffSummary)
      ? !AIBerlinTurnOffSummary.includes((heroBook?.book_id || heroBook?.product_id))
      : true;
    const showPractice = Array.isArray(AIBerlinTurnOffPractice)
      ? !AIBerlinTurnOffPractice.includes((heroBook?.book_id || heroBook?.product_id)) : true;
    let isTopicInQuizFlow = false;
    let enableAIShortAnswer = false;

    if (enableTopicInQuizFlow) {
      const listOfBookIdsTopicInQuizFlow = enableTopicInQuizFlow.split(',');
      isTopicInQuizFlow = listOfBookIdsTopicInQuizFlow.includes(heroBook?.book_id)
        || listOfBookIdsTopicInQuizFlow.includes(heroBook?.product_id);
    }
    if (!CommonUtils.isEmpty(listOfBookAIShortAnswer)
      && listOfBookAIShortAnswer.titles
      && listOfBookAIShortAnswer.titles.length > 0) {
      enableAIShortAnswer = listOfBookAIShortAnswer.titles.some(
        title => (heroBook?.book_id === title?.bookId || heroBook?.product_id === title?.productId)
      );
    }

    const { enableNewMathConfig } = CommonUtils.commonAIConfig(flags, heroBook, { isAITutorEnabled });

    if (!this.AIStudyToolInitialLoad) {
      this.AIStudyToolEvent(heroBook);
    }

    return (
      <AuthHomeChatComponent
        isPageLoaded={!!bannerType && isTableOfContentsFetched}
        users={{
          username,
          initials,
          isFirstTimeAITutorUser: false
        }}
        masterTheme="plus"
        theme="default"
        context={{
          bookTitle,
          productModel,
          indexId,
          pageId: lastLocationPageId,
          bookID,
          secondaryBookId: productId,
          chapterList,
          chapterId,
          chapterTitle: lastLocationChapterTitle,
          chapterNumber,
          contentType,
          tenantObj: {
            tenantId: env.TENANT_ID_ETEXT,
            tenantKey: env.TENANT_KEY_ETEXT
          },
          berlinTenantObject: {
            tenantId: env.BERLIN.TENANT_ID,
            tenantKey: env.BERLIN.TENANT_KEY
          },
          currentSectionIds: []
        }}
        config={{
          enableMathMLKeyboard,
          AIEnableMarkdown,
          enableProblemSolveFeature,
          enableFreeResponse,
          enableParagraph,
          topicTimeOut,
          enableStreamingAPI,
          delayMessageTimerInSecs,
          enableShowMore,
          enableAISleepMode,
          showAISleepModeAB,
          showSummaryQuiz,
          enableExplainQuizOptions,
          AIChatPlaceHolderText,
          enableTopicInQuizFlow: isTopicInQuizFlow,
          ChannelsVideoScore,
          showUserHistory,
          enableAIShortAnswer,
          hideChannelsPopout,
          showRecommendedPrompts,
          showSourceReferencePage: false,
          showSummary,
          showPractice,
          enableNewMathConfig
        }}
        streamHeader={{
          Accept: 'text/event-stream',
          'Content-Type': 'application/json'
        }}
        streamBaseUrl={env.BERLIN.STREAM_BASE_URL}
        showChatBotIcon
        executeCommand={executeCommand}
        openBotContainer={openBotContainer}
        pageList={[]}
        handleGAEvents={this.handleGAEvents}
        handleChatbotOpened={() => {}}
        onLDTrack={() => {}}
        setAITutorAutoOpen={() => {}}
        handleCancelRequest={() => {}}
        onBotClose={this.handleBotClose}
        toggleBotContainer={this.updateBotContainer}
        getBerlinClientAPI={this.getBerlinClientAPI}
        getAuthToken={this.getAuthToken}
        getCurrentSection={
          pageIdRequested => this.handleCurrentSection(pageIdRequested, tocData?.list || [], pagePlaylist, contentType, '')
        }
      />
    );
  }

  // eslint-disable-next-line consistent-return
  handleCurrentSection = (pageIdRequested, tocDataList, pagePlaylist, contentType) => {
    try {
      return this.getCurrentSection(pageIdRequested, tocDataList, pagePlaylist, contentType, '');
    } catch (e) {
      console.log('getCurrentSection error', e);
    }
  }

  fetchHeroBookAsset = () => {
    const isMLM = this.location === constants.MLM_AUTH_HOME;
    const heroDashboardPlugin = this.context.heroDashboardPlugin;
    const heroBook = heroDashboardPlugin.getHeroBook();
    const { flags } = this.context?.state;
    const {
      showAITutor,
      isAIReviewUser
    } = flags || {};
    const isAITutorEnabled = CommonUtils.isAITutorEnabled(showAITutor, isAIReviewUser, heroBook?.isAITutorSupported);
    const isNotPdf = !(heroBook && heroBook.product_model === constants.ETEXT_PDF);

    if (isMLM && isNotPdf && heroBook && isAITutorEnabled) {
      heroDashboardPlugin.fetchAsset(heroBook);
    }
  }

  getAuthToken = (callback) => {
    this.getToken().then(async (tokenConfig) => {
      await callback(tokenConfig.token);
    });
  };

  setBerlinServiceHeaders = (berlinClientService) => {
    if (berlinClientService?.defaults?.headers?.common) {
      const cancelTokenSource = axios.CancelToken.source();
      // eslint-disable-next-line no-param-reassign
      berlinClientService.defaults.cancelToken = cancelTokenSource.token;
      // eslint-disable-next-line no-param-reassign
      berlinClientService.defaults.timeout = env.BERLIN_URL_TIMEOUT;
    }
  }

  getBerlinClientAPI = () => {
    const berlinClientService = Framework.getClientManager().get('berlin');
    this.setBerlinServiceHeaders(berlinClientService);
    const berlinClient = new BerlinServices(berlinClientService);

    return berlinClient;
  };

  getToken = () => new Promise((resolve) => {
    window.piSession.getToken((status, accessToken) => {
      resolve({
        id: window.piSession.userId(),
        type: 'PI',
        token: accessToken
      });
    });
  })

  getAIChatChapterListAndToc = (args = {}) => {
    try {
      const { languages, asset } = this.context.props;
      const { flags, LDUserFlagsFetched } = this.context.state;
      const {
        tableOfContents: heroTableOfContents,
        heroBook
      } = args;
      const { isTOCFetchFailed } = heroTableOfContents || {};
      const isTableOfContentsFetched = !isTOCFetchFailed
        && (heroTableOfContents && !CommonUtils.isEmpty(heroTableOfContents));
      const isPdfContent = heroBook.book_type === constants.CONTENT_TYPE_PDF;
      const assetStore = getSnapshot(asset);

      if (!isTableOfContentsFetched) return [];

      const { tableOfContents } = TocMapper.getTocAndAudioContents(
        [...assetStore.children],
        heroBook.book_id,
        heroBook.course_id,
        isPdfContent,
        languages,
        flags.enableTOCPrintPage,
        LDUserFlagsFetched
      );

      return { chapterList: this.getChapterList(tableOfContents), tocData: tableOfContents };
    } catch (error) {
      return {
        chapterList: [],
        tocData: {}
      };
    }
  }

  getChapterList = (tableOfContents) => {
    const chapterList = [];
    tableOfContents.list.forEach((toc) => {
      const chapterData = {
        chapterId: toc.chapterId,
        chapterTitle: toc.title,
        pageIds: [],
        chapterNumber: toc.chapterNumber
      };
      // eslint-disable-next-line no-unused-expressions
      (toc.href || toc.uri) && chapterData.pageIds.push(toc.id);
      if (toc.children && toc.children.length) {
        chapterData.pageIds = [...chapterData.pageIds, ...this.getPageIdsInsideChapter(toc.children)];
      }
      chapterList.push(chapterData);
    });
    return chapterList;
  }

  getPageIdsInsideChapter = (childElements, updatedIds) => {
    const pageIds = updatedIds || [];
    // eslint-disable-next-line array-callback-return
    childElements.map((child) => {
      // eslint-disable-next-line no-unused-expressions
      (child.href || child.uri) && pageIds.push(child.id);
      if (child.children && child.children.length) {
        this.getPageIdsInsideChapter(child.children, pageIds);
      }
    });
    return pageIds;
  }

  getCurrentChapterId = (chapterList, lastLocationPageId) => {
    let currretPageId = '';
    if (chapterList && chapterList.length > 0) {
      const chapter = chapterList.find(({ pageIds }) => (pageIds && pageIds.length > 0 && pageIds.find(pageId => pageId === lastLocationPageId)));
      if (chapter) {
        currretPageId = chapter.chapterId;
      }
    }

    return currretPageId;
  }

  handleBotClose = () => {
    this.context.setState({

      executeCommand: '',
      openBotContainer: false
    });
  }

  updateBotContainer = () => {
    this.context.setState(({ openBotContainer }) => ({ openBotContainer: !openBotContainer }));
  }

  AIStudyToolEvent = (heroBook = {}) => {
    const {
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      AI_STUDY_TOOL_EVENT
    } = constants;

    const customFields = {
      container_value: CommonUtils.containerValue(heroBook)
    };

    this.AIStudyToolInitialLoad = true;
    CommonUtils.dispatchGaEvent(
      AUTH_HOME_CATEGORY,
      EVENT_AUTHHOMEEVENT,
      AI_STUDY_TOOL_EVENT.VIEW_AI_STUDY_TOOL_EVENT_ACTION,
      null,
      customFields
    );
  }

  getCurrentSection = (pageIdRequested, tableOfContents, playlist, contentType, basePath, currentSectionUpdated = [], tocSlateParent, currentChapterId = [], actualPageUrl = '') => {
    const currentSection = currentSectionUpdated || [];
    const chapterId = currentChapterId || [];
    let pageIdValue = pageIdRequested;
    if (typeof pageIdRequested === 'object') {
      const { pageId } = pageIdRequested;
      pageIdValue = pageId;
    }
    // eslint-disable-next-line array-callback-return
    tableOfContents.map((toc) => {
      const tocType = toc.type.toLowerCase();
      if ((pageIdValue === toc.id || (toc.href && (actualPageUrl === toc.href))) && tocType === constants.PLAYLIST_CHAPTER) {
        if (contentType === constants.CONTENT_TYPE_PDF || contentType === constants.CONTENT_TYPE_SPDF) {
          if (toc.level === 1) {
            // eslint-disable-next-line no-unused-expressions
            toc.actualPageUri && currentSection.push(toc.actualPageUri.split('.pdf')[0]) && chapterId.push(toc?.chapterId);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (toc.children && toc.children.length) {
              // eslint-disable-next-line no-unused-expressions
              toc.actualPageUri && currentSection.push(toc.actualPageUri.split('.pdf')[0]) && chapterId.push(toc?.chapterId);
              toc.children.forEach((list) => {
                // eslint-disable-next-line no-unused-expressions
                list.actualPageUri && currentSection.push(list.actualPageUri.split('.pdf')[0]) && chapterId.push(toc?.chapterId);
              });
            } else {
              currentSection.splice(0, currentSection.length);
              chapterId.splice(0, chapterId.length);
              // eslint-disable-next-line no-unused-expressions
              tocSlateParent.actualPageUri && currentSection.push(tocSlateParent.actualPageUri.split('.pdf')[0]) && chapterId.push(toc?.chapterId);
              tocSlateParent.children.forEach((list) => {
                // eslint-disable-next-line no-unused-expressions
                list.actualPageUri && currentSection.push(list.actualPageUri.split('.pdf')[0]) && chapterId.push(toc?.chapterId);
              });
            }
          }
        } else {
          currentSection.push(toc?.id);
          chapterId.push(toc?.chapterId);
        }
      } else if ((pageIdValue !== toc.id || actualPageUrl !== toc.href) && tocType === constants.PLAYLIST_CHAPTER && toc.children && toc.children.length) {
        this.getCurrentSection(pageIdRequested, toc.children, playlist, contentType, basePath, currentSection, toc, chapterId, actualPageUrl);
      } else if ((pageIdValue === toc.id || (toc.href && (actualPageUrl === toc.href))) && (tocType === constants.TOC_TYPE_MODULE || tocType === constants.TOC_TYPE_SUBMODULE)) {
        if (!(contentType === constants.CONTENT_TYPE_PDF || contentType === constants.CONTENT_TYPE_SPDF)) {
          if (contentType === constants.CONTENT_TYPE_CITE) {
            chapterId.push(toc?.chapterId);
            // eslint-disable-next-line no-unused-expressions
            (toc.uri || toc.href) && currentSection.push(toc?.id);
            // eslint-disable-next-line array-callback-return
            playlist.map((page) => {
              if (page.parentItemId === toc.id) {
                currentSection.push(page.id);
                chapterId.push(toc?.chapterId);
              }
            });
          } else {
            // eslint-disable-next-line no-unused-expressions
            currentSection.push(toc?.id) && chapterId.push(toc?.chapterId);
            // eslint-disable-next-line no-unused-expressions
            toc.children && toc.children.length && toc.children.forEach((list) => {
              currentSection.push(list.id);
            });
          }
        }
      } else if ((pageIdValue !== toc.id || actualPageUrl !== toc.href) && (tocType === constants.TOC_TYPE_MODULE || tocType === constants.TOC_TYPE_SUBMODULE) && toc.children && toc.children.length) {
        this.getCurrentSection(pageIdRequested, toc.children, playlist, contentType, basePath, currentSection, toc, chapterId, actualPageUrl);
      } else if ((pageIdValue === toc.id || (toc.href && (actualPageUrl === toc.href))) && (tocType === constants.PLAYLIST_SLATE || tocType === constants.TOC_TYPE_PAGE)) {
        if (contentType === constants.CONTENT_TYPE_PDF || contentType === constants.CONTENT_TYPE_SPDF) {
          // do nothing
        } else {
          const playListData = playlist.find(page => page.id === pageIdValue || page.href === actualPageUrl);
          const parentItemId = playListData && playListData.parentItemId;
          const parentData = tableOfContents.find(list => list.id === parentItemId);
          const parentPageUriExist = parentData && parentData.id && (parentData.uri || parentData.href);

          if (contentType === constants.CONTENT_TYPE_CITE) {
            chapterId.push(toc?.chapterId);
            // eslint-disable-next-line no-unused-expressions
            (parentPageUriExist) && currentSection.push(parentItemId);
            // eslint-disable-next-line array-callback-return
            playlist.map((page) => {
              if (page.parentItemId === parentItemId) {
                currentSection.push(page.id);
                chapterId.push(toc?.chapterId);
              }
            });
          } else {
            // eslint-disable-next-line no-unused-expressions
            tocSlateParent && tocSlateParent.id && currentSection.push(tocSlateParent.id);
            // eslint-disable-next-line no-unused-expressions
            tocSlateParent && tocSlateParent.children && tocSlateParent.children.length && tocSlateParent.children.forEach((list) => {
              currentSection.push(list.id);
            });
            chapterId.push(toc?.chapterId);
          }
        }
      }
    });
    const chapterData = tableOfContents.find(chapter => chapter.chapterId === chapterId[0]);
    if (!chapterData?.chapterId && typeof pageIdRequested === 'object') {
      let pageUrl = pageIdRequested?.pageUrl;
      const pageUrl1 = pageUrl?.replace('etext-ise', 'plus');
      pageUrl = pageUrl1?.split(basePath);
      return this.getCurrentSection(pageIdValue, tableOfContents, playlist, contentType, basePath, currentSectionUpdated, tocSlateParent, currentChapterId, pageUrl[1]);
    }

    return {
      pageIds: currentSection,
      chapterData: { chapterId: chapterData?.chapterId, chapterNumber: chapterData?.chapterNumber, chapterTitle: chapterData?.title }
    };
  }

  /**
   * Handle AI GA events
   *
   * @param {*} eventType
   * @param {*} additionalFields
   */
  handleGAEvents = (eventType, additionalFields) => {
    try {
      let overrideAdditionalFields = {};
      if (additionalFields && typeof additionalFields === 'object') {
        // Override location_in_app for all gpt events to AuthHome
        overrideAdditionalFields = {
          ...additionalFields,
          location_in_app: constants.LOCATION_IN_APP.HOME2
        };
      }
      CommonUtils.dispatchAIGAEvent(eventType, overrideAdditionalFields);
    } catch (error) {
      console.log('dispatch AI GA events error');
    }
  }
}
